<template>
  <div>
    <v-card width="370" class="mx-auto mt-5" flat>
      <Errors v-bind:errors="errors"/>
    <v-card-title>
      <h4>E-Mail Adresse ändern</h4>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent v-model="valid">

        <v-text-field 
        v-model.trim="changeEmailFrom.password"
        :type="showPassword ? 'text' : 'password'" 
        label="Passwort"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[v => !!v || 'Gib ein Passwort an.']"
        @click:append="showPassword = !showPassword"
        required
        />

        <v-text-field 
        type="email" 
        label="Neue E-Mail"
        autocomplete="email"
        v-model.trim="changeEmailFrom.newEmail"
        :rules="emailRules"
        required
        />
      </v-form>

    </v-card-text>
    <v-card-actions class="mx-2">
      <v-btn 
      color="primary" 
      depressed 
      style="width: 130px;" 
      @click="changeEmail"
      >
      Aktualisieren
      </v-btn>
    </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import { auth, teachersCollection } from '../firebase';
import Errors from '@/components/Errors.vue';

export default {
  name: 'ChangeEmail',

  components: {
    Errors,
  },

  data() {
    return {
      changeEmailFrom: {
        newEmail: '',
        password: ''
      },
      errors: [],
      showPassword: false,
      valid: false,
      emailRules: [
        v => !!v || 'Gib eine E-mail-Adresse an.',
        v => /.+@.+/.test(v) || 'Gib eine gültige E-mail-Adresse ein.',
      ],
    };
  },
  computed: {

  },

  methods: {
    changeEmail() {
      if (this.valid) {
        this.error = '';
        
        try {
          //change email in firestore auth
          auth.signInWithEmailAndPassword(auth.currentUser.email, this.changeEmailFrom.password)
            .then((userCredential) => {
              userCredential.user.updateEmail(this.changeEmailFrom.newEmail)
            })

          //change email in teachersCollection
          teachersCollection
            .doc(auth.currentUser.uid)
            .update({
              email: this.changeEmailFrom.newEmail
          })
        } catch (error) {
          console.log("Error updating documents: ", error.message);
          this.errors.push({
            text: 'Deine E-Mail Adresse konnte nicht geändert werden.', 
            type:'firestore',
          });
          window.scrollTo(0,0);
        }

        if (!this.errors.length){
          this.$router.push('/teacher/' + auth.currentUser.uid);
        }
      }
    },
  }
}
</script>

<style>
/* workarround for bugfix: on iOS the cursor is invisible when first clicking into the field, see https://github.com/vuetifyjs/vuetify/issues/13628 */
.v-text-field > .v-input__control > .v-input__slot {
  transition: background 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
</style>